// tslint:disable:max-line-length
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { Ng2CompleterModule } from 'ng2-completer';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterializeModule } from 'angular2-materialize';
import { MatTableModule } from '@angular/material';

import { LocationAboutService } from '../searchResults/locationAbout/location-about.service';
import { DonationHelperService } from '../searchResults/donationHelper/donation-helper.service';
import { DisasterAboutService } from '../searchResults/disasterAbout/disaster-about.service';
import { DisasterSpecificSurveyService } from '../dashboard/servicesComponets/surveys/disasterSpecificSurvey/disaster-specific-survey.service';
import { GeneralOrganizationBackgroundSurveyService } from '../dashboard/servicesComponets/surveys/generalOrganizationBackground/general-organization-background.service';
import { LocationSpecificSurveyService } from '../dashboard/servicesComponets/surveys/locationSpecificSurvey/location-specific-survey.service';
import { OrganizationService } from '../dashboard/servicesComponets/organizations/organization.service';
import { DisasterAdminService } from '../dashboard/servicesComponets/disaster/disaster.service';
import { ServicesProvidedService } from '../dashboard/servicesComponets/service/service.service';
import { CountryService } from '../dashboard/servicesComponets/country/country.service';
import { CountryListService } from '../../app/dashboard/servicesComponets/surveys/shared/countryList.service';
import { ShortUrlService } from '../shortUrl/short-url.service';

import { DocumentService } from './documentService/document.service';

import { GlobalLoaderFacade } from './globalLoaderFacade/global-loader-facade.service';

import { CurrencyService } from './currencyService/currency.service';
import { CurrencyPickerService } from '../header/currencyPicker/currency-picker.service';

import { GenericSearchFilter } from '../pipes/genericSearchFilter';
import { GenericSortPipe } from '../pipes/genericSortFilter';
import { TruncatePipe } from '../pipes/truncatePipe';
import { TruncateStringArrayPipe } from '../pipes/truncateStringArrayPipe';
import { LocationPipe } from '../pipes/locationPipe';
import { KeysPipe } from '../pipes/keysPipe';
import { UsaStateNamePipe } from '../pipes/usaStateNamePipe';
import { ServiceIconsPipe } from '../pipes/serviceIconsPipe';
import { OrgServiceFilter } from '../pipes/orgServiceFilter';
import { LinkHttpPipe } from '../pipes/addHttpPipe';
import { UniquePipe } from '../pipes/uniquePipe';
import { CustomCurrencyPipe } from '../pipes/customCurrencyPipe';

import {
  CanActivateViaAlreadyLoggedInGuard,
  CanActivateViaSuperAdminGuard,
  CanActivateViaOrgAdminGuard,
  CanActivateViaOrgOrAboveAdminGuard,
  CanActivateViaLocalAdminGuard,
  CanActivateViaLocalOrOrgAdminGuard,
  CanActivateViaAuthGuard,
} from '../common/auth.guard';
// tslint:enable:max-line-length

@NgModule({
  imports: [
    CommonModule,

    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2CompleterModule,
    SlimLoadingBarModule,
    MaterializeModule,
    MatTableModule,
  ],
  declarations: [
    GenericSearchFilter,
    GenericSortPipe,
    TruncatePipe,
    TruncateStringArrayPipe,
    KeysPipe,
    LocationPipe,
    UsaStateNamePipe,
    ServiceIconsPipe,
    OrgServiceFilter,
    LinkHttpPipe,
    UniquePipe,
    CustomCurrencyPipe,
  ],
  providers: [
    CountryService,
    CountryListService,
    CurrencyService,
    CurrencyPickerService,
    ServicesProvidedService,
    DisasterAdminService,
    OrganizationService,

    DocumentService,

    DonationHelperService,
    LocationAboutService,
    DisasterAboutService,
    LocationSpecificSurveyService,
    GeneralOrganizationBackgroundSurveyService,
    DisasterSpecificSurveyService,
    ShortUrlService,

    GlobalLoaderFacade,

    CanActivateViaAuthGuard,
    CanActivateViaSuperAdminGuard,
    CanActivateViaOrgAdminGuard,
    CanActivateViaOrgOrAboveAdminGuard,
    CanActivateViaLocalAdminGuard,
    CanActivateViaLocalOrOrgAdminGuard,
    CanActivateViaAlreadyLoggedInGuard,
  ],
  exports: [
    GenericSearchFilter,
    GenericSortPipe,
    TruncatePipe,
    TruncateStringArrayPipe,
    LocationPipe,
    KeysPipe,
    UsaStateNamePipe,
    ServiceIconsPipe,
    CustomCurrencyPipe,
    OrgServiceFilter,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    Ng2CompleterModule,
    SlimLoadingBarModule,
    MaterializeModule,
    LinkHttpPipe,
    UniquePipe,
    MatTableModule,
  ],
})
export class SharedServicesFeatureModule {}
